'use client';

import React, { useEffect } from 'react';
import { useRouter } from 'next/navigation';

import type { CommunityMember } from '@zealy/queries';
import { isUserConnected, useAuthenticatedUser } from '@zealy/queries';
import { roleIsAtLeast } from '@zealy/utils';

type CommunityLayoutProps = {
  params: {
    subdomain: string;
  };
};

export default function Page({ params: { subdomain } }: CommunityLayoutProps) {
  const user = useAuthenticatedUser<CommunityMember>();
  const router = useRouter();

  useEffect(() => {
    if (user.isFetched || !isUserConnected()) {
      if (roleIsAtLeast(user.data?.role, 'editor'))
        router.replace(`/cw/${subdomain}/questboard/admin`);
      else router.replace(`/cw/${subdomain}/questboard`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <div className="w-full min-w-0 h-fill rounded-md bg-secondary" />;
}
